<template>
  <div class="login-container">
    <!--引入粒子特效-->
    <div class="login-bg-box">
      <vue-particles
        color="#fff"
        :particle-opacity="0.7"
        :particles-number="60"
        shape-type="circle"
        :particle-size="4"
        lines-color="#fff"
        :lines-width="1"
        :line-linked="true"
        :line-opacity="0.4"
        :lines-distance="150"
        :move-speed="2"
        :hover-effect="true"
        hover-mode="grab"
        :click-effect="true"
        click-mode="push"
        class="lizi"
      />
    </div>

    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">

      <div class="title-container">
        <h3 class="title">{{ systemTitleName ? systemTitleName : '数据可视化平台' }}</h3>
      </div>

      <el-form-item prop="username">

        <span class="svg-container">
         <el-icon ><User /></el-icon>
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="用户名"
          name="username"
          type="text"
          tabindex="1"
          auto-complete="on"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <!-- <svg-icon icon-class="password" /> -->
          <el-icon><Lock /></el-icon>
        </span>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          :type="passwordType"
          placeholder="密码"
          name="password"
          tabindex="2"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
        />
        <span class="show-pwd" >
          <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>

      <el-button :loading="loading" type="primary" size="medium" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">登录</el-button>

      <div class="tips">
        <!--        <span style="margin-right:20px;">username: admin</span>-->
        <!--        <span> password: any</span>-->
      </div>

    </el-form>
  </div>
</template>

<script>
import { validUsername } from '@/utils/validate'
import { login } from '@/api/index'
import {getDimRUnMode} from "../api/count";
import {getDay} from "../utils/util";
import { getToken, setToken } from "@/utils/auth"
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('Please enter the correct user name'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码必须大于6位'))
      } else {
        callback()
      }
    }
    return {
      systemTitleName: '数据可视化平台',
      loginForm: {
        username: '',
        password: ''
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      //  表单验证规则
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
    }
  },
  created() {
    if (localStorage.systemName && localStorage.systemName != '管理员') return this.systemTitleName = localStorage.systemName
    else if (location.href.indexOf('systemName') != -1) {
      let screnObj = {}
      let hashArr = location.href.split('?')[1].split('&')
      for(let i = 0; i < hashArr.length; i++) screnObj[hashArr[i].split('=')[0]] = hashArr[i].split('=')[1];
      if (screnObj.systemName) return this.systemTitleName = decodeURI(screnObj.systemName)
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {

          this.loading = true
          this.loginForm.grant_type='password'
          this.loginForm.client_id='web'
          this.loginForm.client_secret='123456'
          const formData = new FormData()
          for (const i in this.loginForm) {
             formData.append(i, this.loginForm[i])
          }
          login(formData).then(res => {
            // let {message} = res;
            // alert(message)
            setToken(res.value)
            let token = getToken();

            this.$router.push({path: '/'})
            this.loading = false
          }).catch((res) => {
            this.loading = false
          })
          // login('auth/login', this.loginForm).then(() => {
          //   if (this.passType) localStorage.user = JSON.stringify(this.loginForm)
          //   else localStorage.removeItem('user')
          //   this.$router.push({path: '/'})
          //   this.loading = false
          // }).catch(() => {
          //   this.loading = false
          // })
        } else {
          this.loading = false
          return false
        }
      })
    },
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container {
    color: $cursor;
  }
}

.el-input__wrapper {
  background: transparent !important;
}
.el-input__wrapper {
  width: 100% !important;
}
.el-button {
  height: 50px;
}

.login-bg-box {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

/* reset element-ui css */
.login-container {
  width: 100vw;
  height: 100vh;
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    background: transparent !important;
    input {
      background: transparent !important;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      height: 47px;
      caret-color: $cursor;
      outline: none !important;
      border: none !important;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
      }
    }
    .el-input__inner {
      background-color: transparent !important;
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  //background-color: $bg;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 0 35px 0;
    overflow: hidden;
    background: white;
    border-radius: 20px;
    top: 30%;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    // padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 47px;
    height: 47px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
      color: black;
      margin: 20px auto;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
